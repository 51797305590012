<template>
  <div>
    <v-row>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-model="receipt.classify"
          :items="classifyOptions"
          label="Phân loại"
          placeholder="Phân loại"
          dense
          outlined
          hide-details
          class="c-input-small"
          @change="onClassifyChanged"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-autocomplete
          v-if="[1, 2].includes(receipt.classify)"
          v-model="receipt.id_warehouse"
          :items="warehouseOptions"
          label="Đối tượng"
          placeholder="Đối tượng"
          dense
          outlined
          hide-details
          class="c-input-small"
        ></v-autocomplete>
        <v-autocomplete
          v-else
          v-model="receipt.id_distributor"
          :items="distributorOptions"
          label="Đối tượng"
          placeholder="Đối tượng"
          dense
          outlined
          hide-details
          class="c-input-small"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          v-model="receipt.container_code"
          label="Mã cont"
          placeholder="Mã cont"
          dense
          outlined
          hide-details
          class="c-input-small"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2">
        <date-time-picker
          v-model="receipt.available_at"
          label="Thời gian active"
          placeholder="Thời gian active"
          :single-line="false"
          :min-date="minDate"
        ></date-time-picker>
      </v-col>
      <v-col cols="12" md="2">
        <v-file-input
          accept=".xlsx"
          v-model="file"
          label="Chọn file"
          placeholder="Chọn file"
          dense
          outlined
          hide-details
          class="c-input-small"
          append-icon="mdi-paperclip"
          prepend-icon=""
          @change="onInputFileChange"
        ></v-file-input>
      </v-col>
      <v-col>
        <v-btn
          color="success"
          block
          :disabled="isDisabledBtn"
          @click="assignUid"
        >
          {{ $t("labels.confirm") }}
        </v-btn>
      </v-col>
    </v-row>

    <div class="mt-10">
      <v-simple-table
        fixed-header
        height="calc(100vh - 205px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center">UID</th>
              <th class="text-center">Relevant ID</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in items"
              :key="`au_${key}`"
              class="text-center"
            >
              <td>{{ item.uid }}</td>
              <td>{{ item.relevant_id }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div
        class="text-right error--text fs-14px font-weight-medium font-italic pt-2"
      >
        Tổng: {{ items.length }}
      </div>
    </div>
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import { httpClient } from "@/libs/http";
import { DC_CLASSIFY_OPTIONS } from "@/libs/const";
import moment from "moment/moment";

export default {
  name: "ActiveUid",
  data: () => ({
    isLoading: false,
    file: null,
    items: [],
    distributors: [],
    warehouses: [],
    receipt: {
      classify: null,
      container_code: null,
      id_warehouse: null,
      id_distributor: null,
      available_at: null,
    },
    classifyOptions: [...DC_CLASSIFY_OPTIONS],
  }),
  computed: {
    isDisabledBtn() {
      return (
        !this.receipt.classify ||
        !this.receipt.container_code ||
        (!this.receipt.id_warehouse && !this.receipt.id_distributor) ||
        !this.items ||
        this.items.length === 0
      );
    },
    minDate() {
      return moment().add(1, "hour").format("YYYY-MM-DD");
    },
    warehouseOptions() {
      return [...this.warehouses]
        .filter((w) => w.type == this.receipt.classify)
        .map((w) => ({
          text: w.code_name,
          value: w.id,
        }));
    },
    distributorOptions() {
      return [...this.distributors].map((d) => ({
        text: d.name,
        value: d.id,
      }));
    },
  },
  mounted() {
    const { warehouses } = window.me;
    this.warehouses = [...warehouses];
    this.getDistributors();
  },
  methods: {
    onClassifyChanged() {
      const receipt = {
        ...this.receipt,
        id_warehouse: null,
        id_distributor: null,
      };
      if (receipt.classify == 3) {
        receipt.available_at = null;
      }
      this.receipt = { ...receipt };
    },
    resetAll() {
      this.items = [];
      this.file = null;
      this.receipt = {
        classify: null,
        container_code: null,
        id_warehouse: null,
        id_distributor: null,
        available_at: null,
      };
    },
    async getDistributors() {
      const { data } = await httpClient.post("/dc-distributor-all", {
        is_active: true,
        level: 1,
      });
      this.distributors = [...data];
    },
    async assignUid() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;
      try {
        await httpClient.post("/dc-receipt-create", {
          items: this.items,
          ...this.receipt,
        });
        this.isLoading = false;
        this.resetAll();
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async onInputFileChange(file) {
      if (file) {
        const mappingFields = {
          UID: "uid",
          "Relevant ID": "relevant_id",
        };

        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const values = utils.sheet_to_json(worksheet);
          const items = [...values].map((v) => {
            const item = {};
            Object.keys(v).forEach((i) => {
              item[mappingFields[i]] = `${v[i]}`.trim();
            });
            return item;
          });
          this.validateData(items);
        };
        await reader.readAsArrayBuffer(file);
      }
    },
    validateData(items) {
      if (!items || items.length === 0) {
        this.$vToastify.error("Không có dữ liệu");
        return false;
      }

      const checkNull = [...items].filter(
        (item) => !item.uid || !item.relevant_id
      );

      if (checkNull && checkNull.length > 0) {
        this.$vToastify.error("Không được để trống");
        return false;
      }

      this.items = [...items];
    },
  },
};
</script>
